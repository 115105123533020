import React, { useState } from 'react';
import axios from 'axios';

function App() {
  const [file, setFile] = useState(null);
  const [format, setFormat] = useState('mp4');
  const [downloadUrl, setDownloadUrl] = useState('');
  const [isConverting, setIsConverting] = useState(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setDownloadUrl(''); // Clear previous download URL
  }

  const handleFormatChange = (e) => {
    setFormat(e.target.value);
  }

  const handleSubmit = async () => {
    if (!file) {
      alert('Please select a file first.');
      return;
    }

    setIsConverting(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('format', format);

    try {
      const response = await axios.post('https://freeconvert.codestorms.com/convert', formData, {
        responseType: 'blob', // Important for downloading files
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      setDownloadUrl(url);
    } catch (error) {
      console.error('Conversion error', error);
      alert('Conversion failed!');
    } finally {
      setIsConverting(false);
    }
  }

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', `converted.${format}`); // Create a link with the download attribute
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }

  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <h1>Video Converter</h1>
      <input type="file" onChange={handleFileChange} style={{ margin: '10px' }} />
      <select onChange={handleFormatChange} value={format} style={{ margin: '10px' }}>
        <option value="mp4">MP4</option>
        <option value="avi">AVI</option>
        <option value="mov">MOV</option>
        <option value="wmv">WMV</option>
        <option value="flv">FLV</option>
        <option value="mkv">MKV</option>
        <option value="webm">WebM</option>
        {/* Add more formats as needed */}
      </select>
      <button onClick={handleSubmit} style={{ margin: '10px' }} disabled={isConverting}>
        {isConverting ? 'Converting...' : 'Convert'}
      </button>
      {downloadUrl && (
        <button onClick={handleDownload} style={{ margin: '10px' }}>
          Download Converted File
        </button>
      )}
    </div>
  );
}

export default App;

